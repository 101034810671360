<template>
  <form
    class="BBRAddressForm"
    @submit.prevent="onSubmit">
    <!-- Auto complete area -->
    <div class="AutoCompleteWrap -j-auto-complete-wrap">
      <InputField
        ref="addressField"
        name="address"
        disableAutoSuggest
        :placeholder="mixWB('STREET_AND_NUMBER')"
        :value="address"
        @on-update='onFieldUpdate'/>
      <div
        v-if="showSuggestions"
        class="AddressSuggestions">
        <span
          v-for="item in suggestions"
          :key="item.forslagstekst"
          @click="onSuggestionClick(item)">{{ item.forslagstekst }}</span>
      </div>
      <div
        v-if="showNoSuggestionMessage"
        class="AddressSuggestions">
        <span><i>{{ mixWB('NO_SUGGESTIONS_TO_SHOW') }}</i></span>
      </div>
    </div>

    <!-- Selected area preview -->
    <div class="PreviewWrap">
      <span class="Title">{{ mixWB('SELECTED_ADDRESS') }}</span>
      <div class="PreviewArea">
        <span
          v-if="!selectedAddress || selectedAddress.type === 'vejnavn'"
          class="NoAddressText">{{ mixWB('NO_ADDRESS_SELECTED') }}</span>
        <template v-else>
          <span>
            {{ selectedAddress.data.vejnavn }} {{ selectedAddress.data.husnr }}
          </span>
          <span>
            {{ `${
              selectedAddress.data.postnr
            } ${
              selectedAddress.data.postnrnavn
            }` }}
          </span>
        </template>
      </div>
    </div>

    <span
      class="ErrorText"
      v-if="errorText">{{ errorText }}</span>
    <Button
      type="submit"
      :text="buttonText"
      :isLoading="isLoading" />
  </form>
</template>

<script>
import Button from '@/components/Buttons/Button'
import InputField from '@/components/FormElements/InputField.vue'
import { throttle } from 'lodash-es'
import { getClosestByClass } from '@/globals/javascript/_util/find-elements'

export default {
  name: 'BBRAddressForm',
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      address: '',
      isAddressFocused: false,
      selectedAddress: null,
      suggestions: [],
      errorText: '',
    }
  },
  computed: {
    showSuggestions() {
      if (!this.suggestions.length) {
        return false
      }
      if (!this.isAddressFocused) {
        return false
      }
      if (this.selectedAddress) {
        if (
          this.selectedAddress.type !== 'vejnavn'
          && this.address === this.selectedAddress.forslagstekst
        ) {
          return false
        }
      }
      return true
    },
    showNoSuggestionMessage() {
      if (!this.address || !this.isAddressFocused) {
        return false
      }
      if (this.suggestions.length) {
        return false
      }
      if (this.selectedAddress && this.address === this.selectedAddress.forslagstekst) {
        return false
      }
      return true
    },
  },
  methods: {
    onFieldUpdate(field) {
      this.errorText = ''
      this[field.name] = field.value.trim()

      if (field.name === 'address') {
        this.throttledGetAddressSuggestions.call(this)
      }
    },
    onSuggestionClick(item) {
      this.selectedAddress = item
      this.address = item.forslagstekst

      setTimeout(() => {
        this.throttledGetAddressSuggestions.call(this)
        this.$refs.addressField.$refs.inputField.focus()
        this.isAddressFocused = true
        if (item.type === 'vejnavn') {
          this.address += ' '
        }
      }, 25)
    },
    onSubmit() {
      // Validate address
      if (!this.selectedAddress) {
        this.errorText = this.mixWB('SELECT_AN_ADDRESS')
        return
      }
      if (this.selectedAddress.type === 'vejnavn') {
        this.errorText = this.mixWB('SELECT_A_SPECIFIC_ADDRESS_NOT_JUST_A_STREET')
      }

      // Create screening
      this.$emit('form-submit', this.selectedAddress)
    },
    onWindowClick(e) {
      const parent = getClosestByClass('-j-auto-complete-wrap', e.target)
      this.isAddressFocused = !!parent
    },
    throttledGetAddressSuggestions: throttle(
      function getAddressSuggestions() {
        this.$http.get(`https://dawa.aws.dk/autocomplete?q=${ this.address }`, {
          params: {
            per_side: 20,
            type: 'adgangsadresse',
          },
        })
          .then((res) => {
            this.suggestions = res.data
          })
      }, 300,
    ),
  },
  components: {
    InputField,
    Button,
  },
  mounted() {
    window.addEventListener('click', this.onWindowClick)
  },
  destroyed() {
    window.removeEventListener('click', this.onWindowClick)
  },
}
</script>

<style lang="stylus" scoped>
.AutoCompleteWrap
  position relative

.AddressSuggestions
  position absolute
  top calc(100% - 1px)
  left 0
  width 100%
  border 1px solid $color_grey_lighter
  background-color #fff
  z-index 1
  cursor pointer
  max-height 300px
  overflow auto
  span
    display block
    padding 10px
    border-bottom 1px solid $color_grey_lighter
    &:last-child
      border-bottom none

.PreviewWrap
  margin-bottom 20px
  .Title
    padding-top 10px
    padding-bottom 2px
    display block
    font-size 0.875rem
    text-transform uppercase
  .PreviewArea
    padding 15px
    border 1px solid $color_grey_lighter
    background-color $color_grey_lightest
  .NoAddressText
    color $color_grey
    font-style italic
  span
    display block

.ErrorText
  display block
  margin-bottom 10px
  color $color_error
  font-size 0.875rem
</style>
