<template>
  <div class="ButtonWrap">
    <!-- As link -->
    <router-link
      class="Button"
      :class="rootClasses"
      v-if="path && !isDisabled"
      :to="path">
      <span class="Text">{{ text }}</span>
      <div class="LoadingWrap"/>
    </router-link>

    <!-- No link -->
    <button
      v-else
      :class="rootClasses"
      class="Button"
      :type="this.type"
      @click="onClick"
      :disabled="isDisabled">
      <span class="Text">{{ text }}</span>
      <div class="LoadingWrap"/>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    type: {
      type: String, // button, submit
      required: false,
      default: 'button',
    },
    text: {
      type: String,
      required: true,
    },
    path: {
      type: [Object, Boolean],
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    color: {
      type: String,
      required: false,
      default: 'primary', // 'primary' | 'grey' | 'salmon'
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: 'medium', // 'small' | 'medium'
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: true,
    },
    hasShadow: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    rootClasses() {
      return {
        IsDisabled: this.isDisabled,
        IsLoading: this.isLoading,
        Link: this.color === 'link',
        ColorPrimary: this.color === 'primary' && !this.outlined,
        PrimaryOutlined: this.color === 'primary' && this.outlined,
        ColorGrey: this.color === 'grey' && !this.outlined,
        GreyOutlined: this.color === 'grey' && this.outlined,
        ColorSalmon: this.color === 'salmon' && !this.outlined,
        SalmonOutlined: this.color === 'salmon' && this.outlined,
        SizeSmall: this.size === 'small',
        SizeMedium: this.size === 'medium',
        IsFullWidth: this.fullWidth,
        HasShadow: this.hasShadow,
      }
    },
  },
  methods: {
    onClick() {
      if (this.isDisabled) {
        return
      }

      if (this.isLoading) {
        return
      }

      if (this.path) {
        return
      }

      this.$emit('button-click')
    },
  },
}
</script>

<style lang="stylus" scoped>
  .ButtonWrap
    margin-bottom 10px

  .Button
    position relative
    flex-center-children()
    text-align center
    cursor pointer
    width inherit
    .Inner
      border 1px solid #000
      height 100%
    .LoadingWrap
      display none
    .Text
      display inline-block
      white-space nowrap
    &.IsLoading
      .Text
        opacity 0
      .LoadingWrap
        position absolute
        top 0
        left 0
        display flex
        align-items center
        justify-content center
        width 100%
        height 100%
        &:after
          content ''
          display block
          width 20px
          height 20px
          border-radius 50%
          border 2px solid transparent
          animation: loading infinite .75s linear
    &.ColorPrimary
      background-color $color_primary
      .Text
        color #fff
      .LoadingWrap
        &:after
          border-left-color #fff
          border-right-color #fff
      &.IsDisabled
        background-color $color_grey_light
        cursor default
    &.PrimaryOutlined
      background-color #fff
      border 1px solid $color_primary
      .Text
        color $color_primary
      .LoadingWrap
        &:after
          border-left-color $color_primary
          border-right-color $color_primary
      &.IsDisabled
        background-color $color_grey_light
    &.ColorGrey
      background-color $color_grey
      .Text
        color #fff
      .LoadingWrap
        &:after
          border-left-color #fff
          border-right-color #fff
      &.IsDisabled
        background-color $color_grey_light
        cursor default
    &.GreyOutlined
      background-color #fff
      border 1px solid $color_grey_light
      .Text
        color $color_grey_dark
      .LoadingWrap
        &:after
          border-left-color $color_grey_light
          border-right-color $color_grey_light
      &.IsDisabled
        background-color $color_grey_light
    &.ColorSalmon
      background-color $color_salmon_light
      .Text
        color #fff
      .LoadingWrap
        &:after
          border-left-color #fff
          border-right-color #fff
      &.IsDisabled
        background-color $color_grey_light
    &.SalmonOutlined
      background-color #fff
      border 1px solid $color_salmon_light
      .Text
        color $color_salmon_light
      .LoadingWrap
        &:after
          border-left-color $color_salmon_light
          border-right-color $color_salmon_light
      &.IsDisabled
        background-color $color_grey_light
    &.SizeSmall
      padding 7px 10px
      font-size 0.875rem
    &.SizeMedium
      min-height 40px
      padding 0px 20px
    &.IsFullWidth
      width 100%
    &.HasShadow
      box-shadow $box_shadow_1
    &.Link
      display inline-block
      box-shadow none
      background none
      .Text
        text-transform initial
      &:hover, &:focus
        .Text
          text-decoration underline

  .Text
    text-transform uppercase
    user-select none
</style>
