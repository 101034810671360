<template>
  <div class="ScreeningsNew">
    <div class="NormalPageTitle">{{ mixWB('START_NEW_SCREENING') }}</div>
    <p class="TopInfoText">{{ mixWB('ENTER_THE_ADDRESS_WHERE_THE_SCREENING_WILL_TAKE_PLACE') }}</p>

    <BBRAddressForm
      :isLoading="isLoading"
      :buttonText="mixWB('LETS_GO')"
      @form-submit="onFormSubmit" />
  </div>
</template>

<script>

import BBRAddressForm from '@/components/Forms/BBRAddressForm.vue'
import EventBus from '@/EventBus'
import * as Sentry from '@sentry/browser'

export default {
  name: 'ScreeningsNew',
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    onFormSubmit(selectedAddress) {
      this.isLoading = true

      this.$store.dispatch('addScreening', selectedAddress)
    },
    onScreeningAdded(screeningID) {
      this.$router.push({ name: 'ScreeningsUpdateProjectDetails', params: { screeningID } })
    },
    onScreeningAddedFailed(err) {
      // TODO: Handle error statement
      Sentry.captureException(err)
      alert(this.mixWB('SOMETHING_WENT_WRONG_PLEASE_CONTACT_AN_ADULT'))
    },
  },
  components: {
    BBRAddressForm,
  },
  mounted() {
    EventBus.$on('screening-is-added', this.onScreeningAdded)
    EventBus.$on('screening-added-failed', this.onScreeningAddedFailed)
  },
  destroyed() {
    EventBus.$off('screening-is-added', this.onScreeningAdded)
    EventBus.$off('screening-added-failed', this.onScreeningAddedFailed)
  },
}
</script>

<style lang="stylus" scoped>
  .ScreeningsNew
    pageWrap()
</style>
